import React, { Component } from 'react';
import CardSlider from "../components/CardSlider";
import Label from "../components/Label";
import Author from "../components/Author";
import TagsList from "../components/TagsList";
import {connect} from "react-redux";

//const API_URL = "http://localhost";
const API_URL = "https://movieteka.com:8443";

async function saveLogs(){
    //get IP
    const response = await fetch('https://geolocation-db.com/json/');
    const data = await response.json();
    //get URL parameters
    const query = new URLSearchParams(window.location.search);
    let session = query.get('session')
    if(session == null){
        session = "direct";
    }
    //logs
    const requestOptionsLogs = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            ip: data.IPv4,
            country_code: data.country_code,
            country_name: data.country_name,
            state: data.state,
            city: data.city,
            latitude: data.latitude,
            longitude: data.longitude,
            postal: data.postal,
            page: "home",
            session: session,
            event: "openPage",
            element: "page",
            elementID: "home"
        })
    };
    await fetch(API_URL + '/log/save', requestOptionsLogs);

    //Remove the success parameter from the URL using replaceState
    const urlWithoutSuccess = window.location.pathname + window.location.search.replace('?session=' + session, '');
    window.history.replaceState({}, document.title, urlWithoutSuccess);
}

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            motivation: [],
            antistress: [],
            relations: [],
            crises: [],
            emigration: [],
            englishBlog: []
        }
    }

    async componentDidMount() {
        //motivation
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ pageNo: 0, pageSize: 10, subject: "motivation" })
        };
        fetch(API_URL + '/doc/slider/docs', requestOptions)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    motivation: data,
                });
            });

        //antistress
        const requestOptions2 = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ pageNo: 0, pageSize: 10, subject: "antistress" })
        };
        fetch(API_URL + '/doc/slider/docs', requestOptions2)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    antistress: data,
                });
            });

        //relations
        const requestOptions3 = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ pageNo: 0, pageSize: 10, subject: "relations" })
        };
        fetch(API_URL + '/doc/slider/docs', requestOptions3)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    relations: data,
                });
            });

        //crises
        const requestOptions4 = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ pageNo: 0, pageSize: 10, subject: "crises" })
        };
        fetch(API_URL + '/doc/slider/docs', requestOptions4)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    crises: data,
                });
            });

        //emigration
        const requestOptions5 = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ pageNo: 0, pageSize: 10, subject: "emigration" })
        };
        fetch(API_URL + '/doc/slider/docs', requestOptions5)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    emigration: data,
                });
            });

        //englishBlog
        const requestOptions6 = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ pageNo: 0, pageSize: 10, subject: "englishBlog" })
        };
        fetch(API_URL + '/doc/slider/docs', requestOptions6)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    englishBlog: data,
                });
            });

        //logs
        await saveLogs();
    }

    render() {
        return (
            <React.Fragment>
                {this.state.relations && this.state.relations.length > 0 && (
                    <React.Fragment>
                        <Label id="relations" text="Отношения"/>
                        <CardSlider slides={this.state.relations} id="slider3"/>
                    </React.Fragment>
                )}
                <Author page="home"/>
                {this.state.antistress && this.state.antistress.length > 0 && (
                    <React.Fragment>
                        <Label id="antistress" text="Антистресс"/>
                        <CardSlider slides={this.state.antistress} id="slider2"/>
                    </React.Fragment>
                )}
                {this.state.crises && this.state.crises.length > 0 && (
                    <React.Fragment>
                        <Label id="crises" text="Кризисы"/>
                        <CardSlider slides={this.state.crises} id="slider4"/>
                    </React.Fragment>
                )}
                <Label id="tagList" text=""/>
                <TagsList></TagsList>
                {this.state.motivation && this.state.motivation.length > 0 && (
                    <React.Fragment>
                        <Label id="motivation" text="Мотивация"/>
                        <CardSlider slides={this.state.motivation} id="slider1"/>
                    </React.Fragment>
                )}
                {this.state.emigration && this.state.emigration.length > 0 && (
                    <React.Fragment>
                        <Label id="emigration" text="Эмиграция"/>
                        <CardSlider slides={this.state.emigration} id="slider5"/>
                    </React.Fragment>
                )}
                {this.state.englishBlog && this.state.englishBlog.length > 0 && (
                    <React.Fragment>
                        <Label id="englishBlog" text="English Blog"/>
                        <CardSlider slides={this.state.englishBlog} id="slider6"/>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user,
    };
}

export default connect(mapStateToProps)(Home);