import React from "react";
import "../styles/footer.css"
import {RiCopyrightLine} from "react-icons/ri";

const Footer = () => {
    return(
        <footer className="footer">
            <p>Copyright <RiCopyrightLine/> 2024 Movieteka.com</p>
        </footer>
    )
}

export default Footer;