import React, { Component } from 'react';
import { connect } from "react-redux";
import "../../styles/admin/writeDoc.css"
import { CKEditor } from 'ckeditor4-react';
import axios from 'axios';
import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';

//const API_URL = "http://localhost";
const API_URL = "https://movieteka.com:8443";

class WriteDoc extends Component{
    constructor(props) {
        super(props);
        this.saveDocument = this.saveDocument.bind(this);
        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onChangeMainImage = this.onChangeMainImage.bind(this);
        this.onChangeTags = this.onChangeTags.bind(this);
        this.onChangeSubject = this.onChangeSubject.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
        this.onFileChange = this.onFileChange.bind(this);

        this.state = {
            id: 0,
            title: "",
            mainImage: "",
            text: "",
            tags: "",
            images: "",
            subject: "",
            liveTime: new Date(),
            editorInstance: null
        };
    }

    componentDidMount() {
        if(this.props.id != "new"){
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.user.accessToken }
            };

            fetch(API_URL + "/write/blog/" + this.props.id, requestOptions)
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        id: data.id,
                        title: data.title,
                        mainImage: data.mainImage,
                        text: data.text,
                        tags: data.tags,
                        images: data.images,
                        subject: data.subject,
                        liveTime: new Date(data.liveTime)
                    });
                    if (this.state.editorInstance) {
                        this.state.editorInstance.setData(data.text);
                    }
                    document.getElementById("images").innerHTML += data.images;
                });
        }
    }

    saveDocument(e) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.user.accessToken },
            body: JSON.stringify(
                {
                    id: this.state.id,
                    title: this.state.title,
                    mainImage: this.state.mainImage,
                    tags: this.state.tags,
                    images: this.state.images,
                    subject: this.state.subject,
                    liveTime: this.state.liveTime,
                    text: this.state.text
                })
        };
        fetch(API_URL + '/write/saveDoc', requestOptions)
            .then(response => response.text())
            .then(data => {
                window.location = "/admin";
            });
    }

    onChangeTitle(e) {
        this.setState({
            title: e.target.value
        });
    }

    onChangeMainImage(e) {
        this.setState({
            mainImage: e.target.value
        });
    }

    onChangeTags(e) {
        this.setState({
            tags: e.target.value
        });
    }

    onChangeText(e) {
        this.setState({
            text: e.editor.getData()
        });
    }

    onChangeSubject(e) {
        this.setState({
            subject: e.value
        });
    }

    onFileChange(e) {
        const selectedFile = e.target.files[0];
        const formData = new FormData();
        formData.append(
            "myFile",
            selectedFile,
            selectedFile.name
        );
        const requestOptions = {
            headers: { 'Authorization': 'Bearer ' + this.props.user.accessToken }
        };
        axios.post(API_URL + "/write/uploadImg", formData, requestOptions)
            .then(res => {
                const url = res.data;
                document.getElementById("images").innerHTML += url + "<br/>";
                this.setState({
                    images: document.getElementById("images").innerHTML
                });
            })
    }

    onEditorReady = (event) => {
        this.setState({ editorInstance: event.editor });
    };

    render() {
        const categories = [
            { value: 'motivation', label: 'motivation' },
            { value: 'antistress', label: 'antistress' },
            { value: 'relations', label: 'relations' },
            { value: 'crises', label: 'crises' },
            { value: 'emigration', label: 'emigration' },
            { value: 'englishBlog', label: 'englishBlog' },
            { value: 'reviews', label: 'reviews' },
            { value: 'training', label: 'training' },
            { value: 'top', label: 'top' },
            { value: 'science', label: 'science' },
            { value: 'trailer', label: 'trailer' },
            { value: 'interview', label: 'interview' },
            { value: 'animated', label: 'animated' },
            { value: 'calendar', label: 'calendar' },
            { value: 'festivals', label: 'festivals' },
            { value: 'premieres', label: 'premieres' },
            { value: 'near', label: 'near' },
            { value: 'starsLife', label: 'starsLife' },
            { value: 'newsDoc', label: 'newsDoc' },
            { value: 'lion', label: 'lion' },
            { value: 'classics', label: 'classics' },
            { value: 'guide', label: 'guide' }
        ]

        return (
            <div className="writeDoc">
                <div className="writeDoc-line-admin">
                    <div className="writeDoc-line-admin-links">
                        <a className="writeDoc-a" href={"/"}>Home</a>
                    </div>
                    <div className="writeDoc-line-admin-links">
                        |
                    </div>
                    <div className="writeDoc-line-admin-links">
                        <a className="writeDoc-a" href={"/admin"}>Admin</a>
                    </div>
                </div>
                <div className="writeDoc-line">
                    <input type="text"
                           name="title"
                           id="title"
                           value={this.state.title}
                           onChange={this.onChangeTitle}
                           className="writeDoc-inputTextWrite"
                           placeholder="Title"
                    />
                </div>
                <div className="writeDoc-line">
                    <input type="text"
                           name="mainImage"
                           id="mainImage"
                           value={this.state.mainImage}
                           onChange={this.onChangeMainImage}
                           className="writeDoc-inputTextWrite"
                           placeholder="Home image"
                    />
                </div>
                <div className="writeDoc-line">
                <input type="text"
                       name="tags"
                       id="tags"
                       value={this.state.tags}
                       onChange={this.onChangeTags}
                       className="writeDoc-inputTextWrite"
                       placeholder="Tags"
                />
                </div>
                <div className="writeDoc-line">
                    <div className="writeDoc-inputTextWriteForImage" name="images" id="images"/>
                    <div className="writeDoc-file-upload">
                        <label>
                            <input type="file" onChange={this.onFileChange}/>
                            <span>Новая картинка</span>
                        </label>
                    </div>
                </div>
                <div className="writeDoc-line-settings">
                    <div className="writeDoc-div-select">
                        <Select
                            className="writeDoc-select"
                            options={categories}
                            onChange={this.onChangeSubject}
                            value={{ value: this.state.subject, label: this.state.subject }}
                        />
                    </div>
                    <div className="writeDoc-div-dateTimePicker">
                        <DateTimePicker
                            onChange={(date) => {
                                this.setState({
                                    liveTime: date
                                });
                            }}
                            value={this.state.liveTime} />
                    </div>
                </div>
                <div className="writeDoc-line">
                    <CKEditor
                        name="ckeditor"
                        onInstanceReady={this.onEditorReady}
                        onChange={this.onChangeText}
                    />
                </div>
                <div className="writeDoc-line-submit-button">
                    <input type="submit"
                           value="Сохранить и выйти"
                           id="saveBtn"
                           onClick={this.saveDocument}
                           className="writeDoc-btnOrange"
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user,
    };
}

export default connect(mapStateToProps)(WriteDoc);